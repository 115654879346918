// JavaScript Document
if(!window.console){
    window.console = {
        log:function(){}
    };
}

var _TrackId='';

(function($) {
       $.promotabplugin = {
            defaultsPromoTabParam: {
                btnContainer: "_promotabcontainer",
                HotelId:'' ,
                GroupId:'',//Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ]
                langkey:"en",
                notecolor:"#8C44B7",
                txtcolor:"#FFFFFF",
                serverurl:"https://live.ipms247.com/",
                IsLayout:0,//Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ]
            }
       };  
	
	$.fn.extend({  
        promotabplugin:function(PromoConfig) {  
            var options = $.extend({}, $.promotabplugin.defaultsPromoTabParam, PromoConfig);  
            options.btnContainer = this.attr("id");
            methods._GetDataContain(options);
        }
    });
       
    var methods = {
        test:'123',
            _GetDataContain:function(opt){
                try{
                    //Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ]- Start
                    if(opt.GroupId != '')
                    {
                        var getUrl=opt.serverurl+'booking/multiproperty_service.php';
                        var param='action=GetPromotionContain&lang_code='+opt.langkey+'&GroupCode='+opt.GroupId;
                    }//Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ] - End
                    else
                    {
                        var getUrl=opt.serverurl+'booking/service.php';
                        var param='action=GetPromotionContain&lang_code='+opt.langkey+'&HotelId='+opt.HotelId;
                    }
                    
                    jQuery.ajax({
                            type: "POST",
                            url: getUrl,
                            data: param,
                            success: function(response) {
                                var response = JSON.parse(jQuery.trim(response));
                                opt.IsLayout = response.IsLayout;//Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ]
                                _TrackId = response.TrackId;
                                var res  = response.content;
                                var MainContain = "<section class='eRpromo-trigger'>"+
                                                    "<span class='close'></span>"
                                                        +res+
                                                "</section>";

                                jQuery("#_promotabcontainer").html(MainContain);
                                jQuery(".eRpromo-trigger").css({'background':opt.notecolor});
                                jQuery(".eRpromo-trigger").css({'color':opt.txtcolor});
                                jQuery('.getBookbtn').css({'color': opt.notecolor});
                                jQuery('.getBookbtn').css({'background': opt.txtcolor});
                            },
                            complete: function(){
                                methods._RedirectToBooking(opt);
                                methods._AfterGetPopup(opt);
                            }
                    });
                }
                catch(err){
                      console.log('error message _GetDataContain');
                      console.log(err);
                }
            },
            _RedirectToBooking:function(opt){
                try{
                    jQuery('.getBookbtn').on('click', function(e){
                        jQuery("a.getBookbtn").removeAttr("href");
                        
                        //Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ] - Start
                        if(opt.IsLayout == 1)
                            window.open(opt.serverurl+'booking/roomwisedata.php?hid='+opt.HotelId+'&BookingThrough=8&L2BTrackId='+_TrackId+'&selectedLang='+opt.langkey+'&IsLayout='+opt.IsLayout,'_blank');
                        else if(opt.IsLayout == 2)
                            window.open(opt.serverurl+'booking/roomwisedata.php?hid='+opt.GroupId+'&BookingThrough=8&L2BTrackId='+_TrackId+'&selectedLang='+opt.langkey+'&IsLayout='+opt.IsLayout,'_blank');
                        else
                            window.open(opt.serverurl+'booking/roomwisedata.php?hid='+ opt.HotelId +'&BookingThrough=8&L2BTrackId=' + _TrackId,'_blank');
                        //Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ] - End
                        
                        
                    });
                }
                catch(err){
                    console.log('error message _RedirectToBooking');
                    console.log(err);
                }
            },
            _AfterGetPopup:function(opt){
                jQuery(document).ready(function($) {
                    jQuery('.promotions-slider').unslider({
                      infinite: true,
                      speed: 300
                    });
                    jQuery('.eRpromo-trigger .title').on('click', function(e) {
                      jQuery('.eRpromo-trigger').toggleClass('open');
                    });
                    jQuery('.eRpromo-trigger .close').on('click', function(e) {
                      jQuery('.eRpromo-trigger').toggleClass('open');
                    });
                });
            }
    }
       	
})(jQuery); 
